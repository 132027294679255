import { resetError, updateLoader, useErrorContextValue, useLoaderContextValue } from '@pasarpolis/common-react-hooks'
import { Suspense, useEffect, useRef, useCallback } from 'react'
import { Provider } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Loader } from '../../components'
import CircularLoader from '../../components/CircularLoader'
import { AgentColorPallette } from '../../configs/colorPallete'
import { useAddFCMToken, useGetAgentDetails, useGetAllProducts, useResetGlobalState, useUpdateGlobal } from '../../state/globalState/hooks/useGlobalContext'
import RestrictedRoute from '../RestrictedRoute'
import routes from '../routes'
import { store } from '../../redux/store'
import get from '../../methods/get'
import { ConfigUrl } from '../../configs/url'
import authActions from '../../redux/auth/action'
import { checkDownTimePeriod, isIos } from '../../helpers/utility'
import { getFCMPayload, webengageLogInEventInIos, webengageScreenVisitedInIos, webengageUserEmailInIos, webengageUserNameInIos, webengageUserPhoneInIos } from '../../bridges/iOS'
import { ANDROID_BRIDGE, webengageLogInEvent, webengageScreenVisited, webengageUserEmail, webengageUserName, webengageUserPhone } from '../../bridges/android'

import { saveFCMTokenBridge } from '../../bridges'
import { isRemovedPath } from '../helpers'
import ShowSnackbar from '../../components/ShowNotification'
import { setToLocalStorage } from '../../helpers/localstorage'
import { REFERRAL_CODE } from '../../constants/cookies'

function RoutesContainer () {
  const [{ globalState: { isLoggedIn } = { }, isLoggedOut }, dispatchGlobal] = useUpdateGlobal()
  const resetGlobalState = useResetGlobalState()
  const [, addFCMTokenReq] = useAddFCMToken()

  const [{ getAgentDetailData = {} }, getAgentDetailRequest] = useGetAgentDetails()
  const [, getAllProductReq] = useGetAllProducts()
  const [{ GET_AGENT_DETAIL_LOADER }, dispatchLoader] = useLoaderContextValue()
  const [{ addDeviceFcmStatus }, dispatchError] = useErrorContextValue()
  const history = useHistory()

  const that = useRef({
    isCheckAuthorizationCall: true,
    isVNAgent: window.location.origin.includes('mitra-vn') || (process.env.REACT_APP_CC === 'VN'),
    isAgentData: '',
    agentData: null,
    fcmToken: ''
  })

  const updateFCMToken = useCallback(async () => {
    if (isIos()) {
      getFCMPayload().then((data) => {
        dispatchGlobal({
          fcmToken: data.fcm_token
        })
        if (data?.isChanged) {
          that.current.fcmToken = data.fcm_token
          addFCMTokenReq({
            body: {
              ...data
            }
          })
        }
      })
    } else if (ANDROID_BRIDGE.getFCMPayload) {
      const data = await ANDROID_BRIDGE.getFCMPayload()
      dispatchGlobal({
        fcmToken: data.fcm_token
      })
      if (data?.isChanged) {
        that.current.fcmToken = data.fcm_token
        addFCMTokenReq({
          body: {
            ...data
          }
        })
      }
    }
  }, [addFCMTokenReq, dispatchGlobal])

  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get('referral_code') && queryParams.get('referral_code') !== '') {
    setToLocalStorage(REFERRAL_CODE, queryParams.get('referral_code'))
  }

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0)
    })
  }, [history])

  useEffect(() => {
    if (addDeviceFcmStatus === 'success' && that.current.fcmToken) {
      saveFCMTokenBridge(that.current.fcmToken)
      that.current.fcmToken = ''
      dispatchError(resetError({
        addDeviceFcmStatus: ''
      }))
    }
  }, [addDeviceFcmStatus, dispatchError])

  useEffect(() => {
    if (that.current.isCheckAuthorizationCall) {
      that.current.isCheckAuthorizationCall = false
      get({ url: ConfigUrl.checkAuthorizationApi }).then(
        (res) => {
          if (res.status === 200) {
            dispatchGlobal({
              globalState: {
                isLoggedIn: true
              },
              showSnackbar: ShowSnackbar
            })
          }
        },
        (err) => {
          dispatchGlobal({
            globalState: {
              err,
              isLoggedIn: false
            },
            showSnackbar: ShowSnackbar
          })
        }
      )
    }
  }, [dispatchGlobal])

  const pathname = window.location.pathname
  useEffect(() => {
    if (isLoggedIn) {
      if (isIos()) {
        webengageScreenVisitedInIos(pathname)
      } else {
        webengageScreenVisited(pathname)
      }
    }
  }, [isLoggedIn, pathname])

  useEffect(() => {
    if (isLoggedIn && getAgentDetailData.agent_code) {
      if (isIos()) {
        webengageLogInEventInIos(getAgentDetailData.agent_code)
      } else {
        webengageLogInEvent(getAgentDetailData.agent_code)
      }
    }
  }, [isLoggedIn, getAgentDetailData.agent_code])

  useEffect(() => {
    if (isLoggedIn && getAgentDetailData.name && getAgentDetailData.phone_no && getAgentDetailData.email) {
      if (isIos()) {
        webengageUserNameInIos(getAgentDetailData.name)
        webengageUserPhoneInIos(getAgentDetailData.phone_no)
        webengageUserEmailInIos(getAgentDetailData.email.toLowerCase())
      } else {
        webengageUserName(getAgentDetailData.name)
        webengageUserPhone(getAgentDetailData.phone_no)
        webengageUserEmail(getAgentDetailData.email.toLowerCase())
      }
    }
  }, [isLoggedIn, getAgentDetailData])

  useEffect(() => {
    if (isLoggedIn && (GET_AGENT_DETAIL_LOADER === undefined)) {
      if (!that.current.isVNAgent) {
        getAllProductReq()
      }
      getAgentDetailRequest()
    }
  }, [
    isLoggedIn, getAgentDetailRequest,
    GET_AGENT_DETAIL_LOADER, getAllProductReq
  ])

  useEffect(() => {
    if (getAgentDetailData.agent_code && getAgentDetailData.agent_ref && (that.current.isAgentData !== getAgentDetailData.agent_ref)) {
      updateFCMToken()
      that.current.isAgentData = getAgentDetailData.agent_ref
      // cookies.set('agent_code', getAgentDetailData.agent_code, {
      //   path: '/',
      //   maxAge: 60 * 60 * 24 * 365 // store for 1 month
      // })
      store.dispatch(authActions.checkAuthorization({
        isLoggedIn: !!getAgentDetailData.agent_code,
        data: getAgentDetailData,
        isVNAgent: that.current.isVNAgent,
        agent_token: true
      }))
      if (
        ['/', '/login'].includes(window.location.pathname)
      ) {
        getAllProductReq()
        history.push('/dashboard')
      }
    }
  }, [getAgentDetailData, history, updateFCMToken, getAllProductReq])

  useEffect(() => {
    if (getAgentDetailData && (that.current.agentData?.red_dot_visibility !== getAgentDetailData?.red_dot_visibility)) {
      that.current.agentData = getAgentDetailData
      store.dispatch(authActions.checkAuthorization({
        isLoggedIn: !!getAgentDetailData.agent_code,
        data: getAgentDetailData,
        isVNAgent: that.current.isVNAgent,
        agent_token: true
      }))
    }
  }, [getAgentDetailData])

  useEffect(() => {
    // handle logout from the container
    if (isLoggedIn === false && isLoggedOut) {
      store.dispatch(authActions.logoutSuccess())
      that.current = {
        isCheckAuthorizationCall: true,
        isAgentData: '',
        fcmToken: '',
        isVNAgent: window.location.origin.includes('mitra-vn') || (process.env.REACT_APP_CC === 'VN')
      }
      dispatchLoader(updateLoader({
        GET_AGENT_DETAIL_LOADER: undefined,
        GET_AGENT_BADGES_LOADER: undefined
      }))
      resetGlobalState()
    }
  }, [isLoggedIn, isLoggedOut, resetGlobalState, dispatchLoader])

  if (isLoggedIn === undefined || (isLoggedIn && !getAgentDetailData.agent_code)) {
    return (
      <div className='page-loader'>
        <CircularLoader isLoading />
      </div>
    )
  }

  if (!isRemovedPath(window.location.pathname) && !window.location.pathname.includes('404')) {
    history.push(`${window.location.pathname}/404`)
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={AgentColorPallette}>
        <Suspense fallback={<Loader visible />}>
          <Switch>
            <Route exact path='/.well-known/assetlinks.json' component={routes.public.deeplinks} />
            <Route exact path='/' component={routes.public.welcomeHome} />
            <Route exact path='/privacy-policy' component={routes.public.privacyPolicy} />
            <Route exact path='/terms-of-use' component={routes.public.termsOfUse} />
            {
              checkDownTimePeriod() && <Route path='*' component={routes.public.downtimePage} />
            }
            <Route exact path='/login' component={routes.public.signin} isLoggedIn={isLoggedIn} />
            <Route exact path='/login/verify' component={routes.public.otp} isLoggedIn={isLoggedIn} />
            <Route exact path='/registration' component={routes.public.registration} />
            <Route exact path='/registration/verify' component={routes.public.otp} />
            <Route exact path='/verify/user/:agentCode/mail/:token' component={routes.public.emailVerified} />
            {
              process.env.NODE_ENV === 'development' && (<Route exact path='/images/view' component={routes.public.images} />)
            }
            {
              (that.current.isVNAgent) && (
                <Route path='*' component={routes.public.welComeAgent} isLoggedIn={isLoggedIn} />
              )
            }
            <Route exact path='/payment/status/:transactionId' component={routes.public.paymentStatus} />
            <Route exact path='/payment/:transactionId' component={routes.public.paymentDetail} />
            {/* Start Customer url not restricted to access this details */}
            <Route exact path='/customer/policy/:policyId' component={routes.public.policyLandingPage} />
            <Route exact path='/customer/draft/policy/:draftQuoteId' component={routes.public.policyLandingPage} />
            <Route exact path='/customer/policy/:policyId/:step/form' component={routes.public.customerPolicyForm} />
            <Route exact path='/customer/draft/policy/:draftQuoteId/:step/form' component={routes.public.customerPolicyForm} />
            <Route exact path='/customer/policy/:policyId/:step/summary' component={routes.public.customerPolicySummary} />
            <Route exact path='/customer/draft/policy/:draftQuoteId/:step/summary' component={routes.public.customerPolicySummary} />
            <Route exact path='/customer/renewal/policy/summary/:policyId/:step' component={routes.public.customerPolicySummary} />
            <Route exact path='/customer/renewal/policy/form/:policyId/:step/:orgPolicyId' component={routes.public.customerPolicyForm} />
            <Route exact path='/customer/payment/:step/:policyId' component={routes.public.customerPolicyPayment} />
            <Route exact path='/customer/waiting/:policyId' component={routes.public.customerWaitingPage} />
            <Route path='/travel/confirmation/:id' component={routes.private.confirmationPage} />
            <Route exact path='/travel/registration/:draftId' component={routes.private.TravelRegistration} />
            <Route exact path='/travel/draft/policy/:id' component={routes.public.policyDocumentTravel} />
            <Route exact path='/extended_warranty/draft/policy/:id' component={routes.public.policyDocumentExtendedWarranty} />
            {/* Customer BCA */}
            <Route exact path='/customer/va/:step/:totalStep/:policyId/:amount' component={routes.public.policyPaymentVA} />
            <Route exact path='/customer/va/detail/:step/:totalStep/:transactionId/:policyId' component={routes.public.policyPaymentVADetail} />
            {/* Mitra driver individual pages */}
            <Route exact path='/mitra-driver' component={routes.public.gojekDriver} />
            <Route exact path='/schedule-mitra' component={routes.public.scheduleMitraGen} />
            {/* Covid Customer Policy */}
            <Route exact path='/:productType/policy/holder/edit/:id' component={routes.public.covidPolicyHolderDetail} />
            <Route path='/:productType/policy/holder' component={routes.public.covidPolicyHolderDetail} />
            {/* <Route exact path='/covid/policy/:id/form' component={routes.public.covidPolicyForm} /> */}
            {/* <Route exact path='/covid/policy/user/:id/details' component={routes.public.covidUserDetails} /> */}
            <Route exact path='/:productType/policy/summary/:id' component={routes.public.covidCustomerConfirmation} />
            <Route exact path='/refer/:agentRef/:productCode' component={routes.public.referPages} />
            <Route path='/refer/:agentRef/:productCode/:leadTrackId' component={routes.public.referPages} />
            <Route exact path='/customer/faqs' component={routes.private.faqsPage} />
            <Route exact path='/customer/:productCode/summary/:policyId' component={routes.public.motorProtectionSummary} />
            {/* Motor cycle customer Pages */}
            <Route path='/motor/form/:productCode/:agentRef' component={routes.public.motorProtectionForm} />
            <Route path='/package/detail/:pkgCode/:agentRef' component={routes.public.customerLippoPkgDetail} />
            <Route exact path='/policy/holder/edit/:pkgCode/:id' component={routes.public.policyHolder} />
            <Route path='/policy/holder/:pkgCode/:agentRef' component={routes.public.policyHolder} />
            {/* Customer Property Changes */}
            <Route exact path='/customer/property/:policyId/:step/form' component={routes.public.propertyCustomerForm} />
            <Route exact path='/customer/property/:policyId/:step/summary' component={routes.public.propertyCustomerSummary} />
            {/* End Customer url not restricted to access this details */}
            <RestrictedRoute path='/dashboard' component={routes.private.dashboard} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/claim/details' component={routes.private.claimDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/review/:step/questions' component={routes.private.questions} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/balance/history' component={routes.private.balanceHistory} isLoggedIn={isLoggedIn} />
            {/* Withdrawal Routes */}
            <RestrictedRoute exact path='/balance/withdrawal' component={routes.private.withdrawal} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/balance/withdrawal/history' component={routes.private.withdrawalHistory} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/balance/withdrawal/success' component={routes.private.withdrawSuccess} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/balance/withdrawal/form' component={routes.private.withdrawalForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/balance' component={routes.private.balance} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/point/transition' component={routes.private.pointConvertPage} isLoggedIn={isLoggedIn} />
            {/* <RestrictedRoute path='/refer-earn' component={routes.private.referEarn} isLoggedIn={isLoggedIn} /> */}
            <RestrictedRoute path='/verify-otp' component={routes.private.verifyOtp} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/bank-otp' component={routes.private.bankOtp} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/help' component={routes.private.help} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/profile' component={routes.private.profile} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/my-profile' component={routes.private.myProfile} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/bank-account/bankform' component={routes.private.bankAccountForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/bank-account/gopayform' component={routes.private.goPayForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/bank-account' component={routes.private.bankAccount} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/agent-address' component={routes.private.agentAddress} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/my-rm/details' component={routes.private.myRmDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/my-downline' component={routes.private.mydownline} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/:type/faqs' component={routes.private.faqsPage} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/mitra/contact' component={routes.private.contact} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/notifications' component={routes.private.notification} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/dependent/agents' component={routes.private.dependentAgents} isLoggedIn={isLoggedIn} />
            {/* {verify KTP and NPWP route} */}
            <RestrictedRoute exact path='/verify/user/ktp' component={routes.private.verifyKTPNPWP} isLoggedIn={isLoggedIn} />
            {/* {App Tutorial} */}
            <RestrictedRoute exact path='/sales/guide' component={routes.private.appTutorial} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/covid/sales/guide' component={routes.private.covidSalesTutorial} isLoggedIn={isLoggedIn} />
            {/* Policy Application Routes */}
            <RestrictedRoute exact path='/motor/:productCode/details' component={routes.private.motorPkgDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/motor_vehicle/v2' component={routes.private.mvPolicyQuote} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/travel/insured_package/:id' component={routes.private.insuredPackage} isLoggedIn={isLoggedIn} />
            <Route path='/travel/package_detail/:id' component={routes.private.insuredPackageDetail} />
            {/* <RestrictedRoute exact path='/motor_vehicle/v2/:draftId' component={routes.private.mvPolicyQuote} isLoggedIn={isLoggedIn} /> */}
            <RestrictedRoute exact path='/motor_vehicle' component={routes.private.policyApplication} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/renew/:productCode/:policyId' component={routes.private.policyApplication} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/packages' component={routes.private.packageListOld} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/motor_vehicle/packages/v2' component={routes.private.packageList} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/form/:step/review' component={routes.private.offlinePolicyReviewForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/offline/detail/:policyId' component={routes.private.offlinePolicyReviewForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/:purchaseType/order' component={routes.private.manualOrder} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/:purchaseType/:quoteId/order/edit' component={routes.private.manualOrder} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/:purchaseType/offline/form' component={routes.private.offlinePolicyForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/:purchaseType/:quoteId/offline/form/edit' component={routes.private.offlinePolicyForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/:purchaseType/offline/review' component={routes.private.offlinePolicyReview} isLoggedIn={isLoggedIn} />
            {/* <RestrictedRoute exact path='/offline/detail/:policyId' component={routes.private.offlinePolicyReview} isLoggedIn={isLoggedIn} /> */}
            <RestrictedRoute exact path='/offline/success/:quoteId' component={routes.private.offlinePolicySuccess} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/offline/orders' component={routes.private.offlinePolicyList} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/offline/quotes/:quoteId' component={routes.private.offlineOrderQuotes} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/offline/quotes/additional/info/:quoteId' component={routes.private.offlineQuoteAdditionalInfo} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/offline/quotes/additional/info/:quoteId/preview' component={routes.private.offlineQuoteAdditionalInfoPreview} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/packages/details' component={routes.private.packageDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/packages/details/v2/:draftQuoteId' component={routes.private.packageDetailsV2} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/renewal/package/details/:policyId' component={routes.private.packageDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/packages/:policyId/form' component={routes.private.applicationForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/form/:draftQuoteId' component={routes.private.mvPolicyVehicleForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/form/:step' component={routes.private.offlineProductManualOrder} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/form/:quoteId/:step/edit' component={routes.private.offlineProductManualOrder} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/customer/data/form/:draftQuoteId' component={routes.private.mvPolicyVehicleCustomerForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/packages/form/:policyId/preview' component={routes.private.policyApplicationDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/renewal/policy/form/:policyId/:orgPolicyId' component={routes.private.applicationForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/motor_vehicle/renewal/policy/preview/:policyId' component={routes.private.policyApplicationDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/payment/:productType/:policyId' component={routes.private.policyPayment} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productType/packages/summary/:policyId' component={routes.private.policyApplicationSummary} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productType/:transactionId/agent/:policyId/payment' component={routes.private.policyApplicationPaymentMethod} isLoggedIn={isLoggedIn} />
            {/* BCA Routes */}
            <RestrictedRoute exact path='/agent/va/:step/:totalStep/:policyId/:amount' component={routes.public.policyPaymentVA} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/agent/va/detail/:step/:totalStep/:transactionId/:policyId' component={routes.public.policyPaymentVADetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/agent/va/detail/:transactionId/:policyId' component={routes.public.paymentVADetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/agent/payment/success/:transactionId/:policyId' component={routes.public.paymentVADetails} isLoggedIn={isLoggedIn} />

            <RestrictedRoute exact path='/packages/success/:transactionId/:policyId' component={routes.private.policyApplicationSuccess} isLoggedIn={isLoggedIn} />
            {/* Transaction Routes */}
            {/* {Covid Routes} */}
            <RestrictedRoute exact path='/:productCode/packages/:pkgType' component={routes.private.packages} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/:productCode/sharing' component={routes.private.covidSharing} isLoggedIn={isLoggedIn} />
            {/* End {Covid Routes} */}
            {/* Start LippoHCP */}
            <RestrictedRoute exact path='/:productCode/:pkgCode/detail' component={routes.private.agentLippoHCPPkgDetail} isLoggedIn={isLoggedIn} />
            {/* End Lippo HCP */}
            {/* Renewal Policy Container */}
            <RestrictedRoute exact path='/renewal/policy/list' component={routes.private.renewalPolicyList} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/renewal/policy/details/:productCode/:policyId' component={routes.private.renewalPolicyDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/transactions' component={routes.private.transactionList} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/transactions/details' component={routes.private.transactionDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/transaction/type/:policyId' component={routes.private.transactionTypeChange} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/draft/quote/detail/:draftQuoteId' component={routes.private.draftTransactionDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/transactions/:policyId/details' component={routes.private.transactionDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/change/policy/data/:policyId' component={routes.private.policyDataChangeContainer} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/change/policy/data/:productCode/:policyId' component={routes.private.policyDataEdit} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/change/policy/addons/:policyId' component={routes.private.policyAddonsChangeContainer} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/change/policy/addons/review/:policyId' component={routes.private.policyAddonsReviewContainer} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/product/banner' component={routes.private.agentProductBanner} isLoggedIn={isLoggedIn} />
            {/* Property Routes */}
            <RestrictedRoute exact path='/property/:productCode/:step/form' component={routes.private.propertyData} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/property/:productCode/:step/building/form' component={routes.private.propertyBuildingData} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/property/:productCode/:step/packages' component={routes.private.propertyPkgs} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/property/:productCode/:step/:pkgCode/details' component={routes.private.propertyPkgDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/property/:policyId/form' component={routes.private.propertyDetailForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/property/:policyId/summary' component={routes.private.propertySummary} isLoggedIn={isLoggedIn} />
            {/* Policy Documents */}
            <RestrictedRoute exact path='/transactions/:policyId/details/documents' component={routes.private.policyDocuments} isLoggedIn={isLoggedIn} />
            {/* Create Endorsement */}
            {process.env.REACT_APP_SHOW_ENDORSEMENT_REQ === 'true' && <RestrictedRoute exact path='/create_endorsement/:policyId' component={routes.private.createEndorsement} isLoggedIn={isLoggedIn} />}
            {/* Instant Payout List */}
            <RestrictedRoute exact path='/instant/payout/transactions' component={routes.private.instantPayoutPolicyList} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/instant/payout/transactions/proof' component={routes.private.instantPaymentProof} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/instant/payout/confirmation' component={routes.private.offlinePaymentConfirmation} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/instant/payout/underpayment/:proofId' component={routes.private.underPayment} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/instant/payout/notification/underpayment/:proofId' component={routes.private.notificationUnderpayment} isLoggedIn={isLoggedIn} />
            {/* E-learning Routes */}
            <RestrictedRoute exact path='/elearning/intro' component={routes.private.elearningIntro} isLoggedIn={isLoggedIn} />
            {/* Marine Cargo Routes */}
            <RestrictedRoute exact path='/marine_cargo' component={routes.private.marineCargoBasicDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/marine_cargo/packages' component={routes.private.marineCargoPackages} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/marine_cargo/:pkgCode/package/details' component={routes.private.marineCargoPackageDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/marine_cargo/packages/:policyId/form' component={routes.private.marineCargoApplication} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/marine_cargo/packages/:policyId/preview' component={routes.private.marineCargoApplicationPreview} isLoggedIn={isLoggedIn} />
            <Route exact path='/customer/marine_cargo/policy/:policyId/form' component={routes.private.marineCargoApplication} />
            <Route exact path='/customer/marine_cargo/packages/:policyId/preview' component={routes.private.marineCargoCustomerApplicationPreview} />
            <RestrictedRoute exact path='/courier-tracking/:courierId/:policyId' component={routes.private.courierTracking} isLoggedIn={isLoggedIn} />

            {/* Provider Workshop Route */}
            <RestrictedRoute exact path='/providers/:providerCode/workshops/' component={routes.private.ProviderWorkshops} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/providers' component={routes.private.Providers} isLoggedIn={isLoggedIn} />

            {/* Health Protection Routes */}
            <RestrictedRoute exact path='/health_protection' component={routes.private.healthProtectionBasicDetails} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/health_protection/packages' component={routes.private.HealthProtectionPackages} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/health_protection/:pkgCode/package/details' component={routes.private.HealthProtectionPackageDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/health_protection/packages/:policyId/form' component={routes.private.HealthProtectionApplication} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/health_protection/:policyId/height_weight_form' component={routes.private.HeightWeightForm} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/health_protection/:policyId/preview' component={routes.private.healthProtectionReview} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/send/quotation/success/:quoteId' component={routes.private.sendQuotationSuccess} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/send/draft/quote/success/:draftQuoteId' component={routes.private.sendQuotationSuccess} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/payment/status/:type/:quoteId' component={routes.private.sendQuotationSuccess} isLoggedIn={isLoggedIn} />

            {/* <RestrictedRoute path='/travel/confirmation/:id' component={routes.private.confirmationPage} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/travel/registration/:draftId' component={routes.private.TravelRegistration} isLoggedIn={isLoggedIn} /> */}
            <RestrictedRoute path='/travel/:id' component={routes.private.TravelDetail} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/travel' component={routes.private.TravelDetail} isLoggedIn={isLoggedIn} />

            <RestrictedRoute exact path='/extended_warranty' component={routes.private.extendedWarranty} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/extended_warranty/insured_package/:id' component={routes.private.extendedWarrantyInsuredPackage} isLoggedIn={isLoggedIn} />
            <RestrictedRoute path='/extended_warranty/package_detail/:id' component={routes.private.extendedWarrantyInsuredPackageDetail} isLoggedIn={isLoggedIn} />
            <Route exact path='/extended_warranty/registration/:draftId' component={routes.private.extendedWarrantyRegistration}/>
            {/* <RestrictedRoute exact path='/extended_warranty/registration/:draftId' component={routes.private.extendedWarrantyRegistration} isLoggedIn={isLoggedIn} /> */}
            <Route exact path='/extended_warranty/confirmation/:draftId' component={routes.private.extendedWarrantyConfirmation}/>

            <Route exact path='/customer/health_protection/packages/:policyId/form' component={routes.private.CustomerHealthProtectionApplication} />
            <Route exact path='/customer/health_protection/:policyId/height_weight_form' component={routes.private.CustomerHeightWeightForm} />
            <Route exact path='/customer/health_protection/:policyId/preview' component={routes.private.HealthProtectionCustomerPreview} />
            <Route exact path='/not_eligible_screen' component={routes.private.notEligibleScreen} />
            <Route exact path='/custom-url' component={routes.public.iframePage} />
            <RestrictedRoute exact path='/agent/:policyId/invoice/documents' component={routes.private.insuranceDocuments} isLoggedIn={isLoggedIn} />
            <RestrictedRoute exact path='/agent/invoice/:invoiceId/detail' component={routes.private.agentInvoiceDetail} isLoggedIn={isLoggedIn} />
            <Route exact path='/customer/invoice/:invoiceId/detail' component={routes.private.customerInvoiceDetail} />
            <Route exact path='/invoice-not-found' component={routes.private.invoiceNotFound} />
            <RestrictedRoute exact path='/claim/detail/:claimId' component={routes.private.PolicyClaim} isLoggedIn={isLoggedIn} />
            <Route path='*' component={routes.public.NotFoundPage} />

            {/* <Redirect to='/dashboard' /> */}
          </Switch>
        </Suspense>
        <ShowSnackbar />
      </ThemeProvider>
    </Provider>
  )
}

export default RoutesContainer
