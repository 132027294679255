import lazyPromise from '../../helpers/lazyPromise'
import { lazy } from 'react'

const routes = {
  public: {
    deeplinks: lazy(() => lazyPromise(() => import('../../.well-known/assetlinks'))),
    welcomeHome: lazy(() => lazyPromise(() => import('../../containers/Public/LandingPage/WelcomeHome'))),
    privacyPolicy: lazy(() => lazyPromise(() => import('../../containers/Public/PrivacyPolicy'))),
    termsOfUse: lazy(() => lazyPromise(() => import('../../containers/Public/TermsOfUse'))),
    signin: lazy(() => lazyPromise(() => import('../../containers/Public/Signin'))),
    otp: lazy(() => import('../../containers/Public/Otp')),
    registration: lazy(() => lazyPromise(() => import('../../containers/Public/Register'))),
    paymentDetail: lazy(() => import('../../containers/Public/PaymentDetail')),
    emailVerified: lazy(() => import('../../containers/Public/EmailVerificationPage')),
    paymentStatus: lazy(() => import('../../containers/Public/PaymentStatus')),
    images: (process.env.NODE_ENV === 'development' && lazy(() => import('../../containers/Public/ViewImages'))),
    // Covid Policy
    referPages: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/ReferPage'))),
    covidPolicyForm: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CovidPolicyForm'))),
    covidPolicyHolderDetail: lazy(() => import('../../containers/Public/Customer/CovidPolicyHolder')),
    covidUserDetails: lazy(() => import('../../containers/Public/Customer/CovidUsersDetails')),
    covidCustomerConfirmation: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CovidCustomerSummary'))),
    // landing Page for policy
    policyLandingPage: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/PolicyLanding'))),
    policyDocumentTravel: lazy(() => import('../../containers/Private/Travel/PolicyDocument')),
    policyDocumentExtendedWarranty: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/PolicyDocument')),
    // Customer Policy
    NotFoundPage: lazy(() => import('../../containers/Public/NotFoundPage')),
    welComeAgent: lazy(() => import('../../containers/Private/WelcomeAgent')),
    downtimePage: lazy(() => import('../../containers/Public/DownTimePage')),
    customerPolicy: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CustomerPolicy'))),
    customerPolicyForm: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CustomerPolicyForm'))),
    customerPolicySummary: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CustomerPolicySummary'))),
    customerPolicyPayment: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CustomerPolicyPayment'))),
    policyPaymentVA: lazy(() => lazyPromise(() => import('../../containers/CommonContainer/PaymentVAPage'))),
    policyPaymentVADetail: lazy(() => lazyPromise(() => import('../../containers/CommonContainer/PaymentVADetail'))),
    paymentVADetails: lazy(() => lazyPromise(() => import('../../containers/Private/Payments/pages/VAPaymentPage'))),
    customerWaitingPage: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/CustomerPaylaterWaitingPage'))),
    // Mitra Driver
    gojekDriver: lazy(() => import('../../containers/Public/LandingPage/GojekDriver/mitraDriver')),
    // SCHEDULE MITRA GEN
    scheduleMitraGen: lazy(() => import('../../containers/Public/LandingPage/GojekDriver/scheduleMitraGen.js/index')),
    // MotorCycle Pkg Pages
    motorProtectionForm: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/MotorProtection/MotorForm'))),
    motorProtectionSummary: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/MotorProtection/MotorSummary'))),
    customerLippoPkgDetail: lazy(() => import('../../containers/Public/Customer/LippoHCP/CustomerLippoPkgDetail')),
    policyHolder: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/PolicyHolder'))),
    // Property
    propertyCustomerForm: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/Property/Pages/PropertyCustomerForm'))),
    propertyCustomerSummary: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/Property/Pages/PropertyCustomerSummary'))),
    iframePage: lazy(() => lazyPromise(() => import('../../containers/Public/iframePage')))

  },
  private: {
    dashboard: lazy(() => lazyPromise(() => import('../../containers/Private/Dashboard'))),
    claimDetails: lazy(() => lazyPromise(() => import('../../containers/Private/Claim'))),
    questions: lazy(() => lazyPromise(() => import('../../containers/Private/Questionnaire'))),
    policy: lazy(() => lazyPromise(() => import('../../containers/Private/Application'))),
    balanceHistory: lazy(() => import('../../containers/Private/Wallet/balanceHistory')),
    balance: lazy(() => lazyPromise(() => import('../../containers/Private/Wallet'))),
    pointConvertPage: lazy(() => import('../../containers/Private/Wallet/Pages/PointConvertPage')),
    help: lazy(() => import('../../containers/Private/Help')),
    profile: lazy(() => lazyPromise(() => import('../../containers/Private/Profile'))),
    myProfile: lazy(() => import('../../containers/Private/Profile/Pages/MyProfile')),
    bankAccount: lazy(() => import('../../containers/Private/Profile/Pages/BankAccount')),
    bankAccountForm: lazy(() => import('../../containers/Private/Profile/Pages/AddBankAccount')),
    goPayForm: lazy(() => import('../../containers/Private/Profile/Pages/AddGoPay')),
    agentAddress: lazy(() => import('../../containers/Private/Profile/Pages/AgentAddress')),
    bankOtp: lazy(() => import('../../containers/Private/Profile/Pages/BankAccount/components/OTPpage')),
    verifyOtp: lazy(() => import('../../containers/Private/Profile/components/VerifyOtpPage')),
    myRmDetails: lazy(() => import('../../containers/Private/Profile/Pages/RmDetails')),
    mydownline: lazy(() => lazyPromise(() => import('../../containers/Private/Profile/Pages/MyDownline'))),
    // referEarn: lazy(() => lazyPromise(() => import('../../containers/Private/Profile/Pages/ReferralEarn'))),
    faqsPage: lazy(() => import('../../containers/Private/Profile/Pages/FAQsPage')),
    contact: lazy(() => import('../../containers/Private/Profile/Pages/ContactPage')),
    notification: lazy(() => import('../../containers/Private/Profile/Pages/Notifications')),
    dependentAgents: lazy(() => lazyPromise(() => import('../../containers/Private/Profile/Pages/DependentAgents'))),
    // covid pages
    packages: lazy(() => lazyPromise(() => import('../../containers/Private/Packages'))),
    covidPkgs: lazy(() => lazyPromise(() => import('../../containers/Private/CovidPackages'))),
    covidSharing: lazy(() => import('../../containers/Private/CovidPkgSharing')),
    // tutorials
    appTutorial: lazy(() => import('../../containers/Private/AppTutorial')),
    covidSalesTutorial: lazy(() => import('../../containers/Private/CovidSalesTutoria')),
    // Policy Application Routes
    policyApplication: lazy(() => lazyPromise(() => import('../../containers/Private/Application'))),
    mvPolicyQuote: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/MVPolicyQuote'))),
    packageList: lazy(() => lazyPromise(() => import('../../containers/Private/Packages/pages/PackageList'))),
    packageListOld: lazy(() => lazyPromise(() => import('../../containers/Private/Packages/pages/PkgListOld'))),
    offlineProductManualOrder: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflineProductManualOrder'))),
    manualOrder: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/ManualFleetPolicy'))),
    offlinePolicyForm: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflinePolicyDetailForm'))),
    offlinePolicyReview: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflinePolicyReview'))),
    offlinePolicyReviewForm: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflinePolicyReviewForm'))),
    offlinePolicySuccess: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflineSuccess'))),
    offlinePolicyList: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflinePolicyList'))),
    offlineOrderQuotes: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflineOfferQuotes'))),
    offlineQuoteAdditionalInfo: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflineQuoteAdditionalInfo'))),
    offlineQuoteAdditionalInfoPreview: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/OfflineQuoteAdditionalInfoReview'))),
    packageDetail: lazy(() => import('../../containers/Private/Application/packages/packageDetail')),
    packageDetailsV2: lazy(() => import('../../containers/Private/Packages/pages/PackageDetails')),
    applicationForm: lazy(() => lazyPromise(() => import('../../containers/Private/Application/form'))),
    mvPolicyVehicleForm: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/MVPolicyVehicleForm'))),
    mvPolicyVehicleCustomerForm: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/MVPolicyCustomerData'))),
    policyApplicationDetail: lazy(() => lazyPromise(() => import('../../containers/Private/Application/form/summary'))),
    policyPayment: lazy(() => lazyPromise(() => import('../../containers/Private/Payments/pages/PaymentPage'))),
    policyApplicationSummary: lazy(() => lazyPromise(() => import('../../containers/Private/Application/summary'))),
    policyApplicationPaymentMethod: lazy(() => import('../../containers/Private/Application/payment')),
    policyApplicationSuccess: lazy(() => import('../../containers/Private/Application/success')),
    // Withdrawal Routes
    withdrawal: lazy(() => lazyPromise(() => import('../../containers/Private/Withdrawal'))),
    withdrawalHistory: lazy(() => import('../../containers/Private/Withdrawal/component/WithdrawalHistory/withdrawalHistory')),
    withdrawalForm: lazy(() => lazyPromise(() => import('../../containers/Private/Withdrawal/component/BankForm'))),
    withdrawSuccess: lazy(() => lazyPromise(() => import('../../containers/Private/Withdrawal/component/WithdrawalSuccess/withdrawSuccess'))),
    // verify KTP and NPWP routes
    verifyKTPNPWP: lazy(() => import('../../containers/Private/VerifyKTPNPWP')),
    // Transactions Routes
    renewalPolicyList: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/RenewalPolicyList'))),
    renewalPolicyDetails: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/RenewalPolicyDetails'))),
    transaction: lazy(() => lazyPromise(() => import('../../containers/Private/Policy'))),
    transactionDetail: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages'))),
    draftTransactionDetail: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/MVDraftDetails'))),
    policyDataChangeContainer: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/PolicyDataChange'))),
    policyDataEdit: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/PolicyDataEdit'))),
    policyAddonsChangeContainer: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/PolicyAddonsChange'))),
    policyAddonsReviewContainer: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/PolicyAddonsReview'))),
    createEndorsement: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/CreateEndorsement'))),
    transactionList: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/TransactionList'))),
    // adira agent side page
    motorPkgDetails: lazy(() => import('../../containers/Private/MotorPkgDetail')),
    // Lippo HCP
    agentLippoHCPPkgDetail: lazy(() => import('../../containers/Private/AgentLippoHCPPkgDetail')),
    // banner img urls
    agentProductBanner: lazy(() => import('../../containers/Private/LandingPages/AgentSunLifeLanding')),
    // Property Pages
    propertyData: lazy(() => lazyPromise(() => import('../../containers/Private/Property/Pages/PropertyData'))),
    propertyBuildingData: lazy(() => import('../../containers/Private/Property/Pages/PropertyBuildingData')),
    propertyPkgs: lazy(() => lazyPromise(() => import('../../containers/Private/Property/Pages/PropertyPkgs'))),
    propertyPkgDetail: lazy(() => import('../../containers/Private/Property/Pages/PropertyPkgDetail')),
    propertyDetailForm: lazy(() => lazyPromise(() => import('../../containers/Private/Property/Pages/PropertyAgentForm'))),
    propertySummary: lazy(() => lazyPromise(() => import('../../containers/Private/Property/Pages/PropertyAgentSummary'))),
    transactionTypeChange: lazy(() => import('../../containers/Private/Policy/pages/TransactionChange')),
    policyDocuments: lazy(() => import('../../containers/Private/Policy/pages/PolicyDocuments')),
    insuranceDocuments: lazy(() => import('../../containers/Private/Policy/pages/InsuranceDocuments')),

    // Instant Payout
    instantPayoutPolicyList: lazy(() => lazyPromise(() => import('../../containers/Private/InstantPayout/Pages/InstantPayoutPolicyList'))),
    instantPaymentProof: lazy(() => lazyPromise(() => import('../../containers/Private/InstantPayout/Pages/InstantPayoutProof'))),
    offlinePaymentConfirmation: lazy(() => lazyPromise(() => import('../../containers/Private/InstantPayout/Pages/OfflinePaymentConfirmation'))),
    underPayment: lazy(() => lazyPromise(() => import('../../containers/Private/InstantPayout/Pages/UnderPayment'))),
    notificationUnderpayment: lazy(() => lazyPromise(() => import('../../containers/Private/InstantPayout/Pages/notificationUnderPayment'))),
    elearningIntro: lazy(() => lazyPromise(() => import('../../containers/Private/elearningIntro'))),
    // marine cargo
    marineCargoBasicDetails: lazy(() => lazyPromise(() => import('../../containers/Private/MarineCargo/Pages/BasicDetails'))),
    marineCargoPackages: lazy(() => lazyPromise(() => import('../../containers/Private/MarineCargo/Pages/Packages'))),
    marineCargoPackageDetail: lazy(() => lazyPromise(() => import('../../containers/Private/MarineCargo/Pages/PackageDetail'))),
    marineCargoApplication: lazy(() => lazyPromise(() => import('../../containers/Private/MarineCargo/Pages/ApplicationForm'))),
    marineCargoApplicationPreview: lazy(() => lazyPromise(() => import('../../containers/Private/MarineCargo/Pages/ApplicationPreview'))),
    courierTracking: lazy(() => lazyPromise(() => import('../../containers/Private/CourierTracking'))),
    marineCargoCustomerApplicationPreview: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/MarineCargoCustomer/pages/MarineCargoCustomerPreview'))),

    // Health Protection
    healthProtectionBasicDetails: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/BasicDetails'))),
    healthProtectionReview: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/HealthProtectionReview'))),
    HealthProtectionPackages: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/Packages'))),
    HealthProtectionPackageDetail: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/PackageDetail'))),
    HealthProtectionApplication: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/ApplicationForm'))),

    sendQuotationSuccess: lazy(() => lazyPromise(() => import('../../containers/Private/SendQuotationSuccess'))),
    HeightWeightForm: lazy(() => lazyPromise(() => import('../../containers/Private/Policy/pages/HealthProtection/Pages/HeightWeightQuestionaire'))),
    CustomerHeightWeightForm: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/pages/HealthProtection/pages/HeightWeightQuestionaire'))),
    HealthProtectionCustomerPreview: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/pages/HealthProtection/pages/HealthProtectionPolicyPreview'))),
    notEligibleScreen: lazy(() => lazyPromise(() => import('../../containers/CommonContainer/NotEligibleScreen'))),
    agentInvoiceDetail: lazy(() => lazyPromise(() => import('../../containers/Private/InvoiceDetail'))),
    customerInvoiceDetail: lazy(() => lazyPromise(() => import('../../containers/Public/InvoiceDetail'))),
    invoiceNotFound: lazy(() => lazyPromise(() => import('../../containers/CommonContainer/InvoiceNotFound'))),
    CustomerHealthProtectionApplication: lazy(() => lazyPromise(() => import('../../containers/Public/Customer/pages/HealthProtection/pages/ApplicationForm'))),
    Providers: lazy(() => import('../../containers/Private/ProviderWorkshop/pages/ProviderList')),
    ProviderWorkshops: lazy(() => import('../../containers/Private/ProviderWorkshop/pages/ProviderWorkshops')),
    PolicyClaim: lazy(() => (import('../../containers/Private/PolicyClaim'))),
    TravelDetail: lazy(() => (import('../../containers/Private/Travel/pages/TravelDetail'))),
    TravelRegistration: lazy(() => (import('../../containers/Private/Travel/pages/Registration'))),
    confirmationPage: lazy(() => import('../../containers/Private/Travel/pages/ConfirmationPage')),
    insuredPackage: lazy(() => import('../../containers/Private/Travel/pages/InsuredPackage')),
    insuredPackageDetail: lazy(() => import('../../containers/Private/Travel/pages/InsuredPackage/components/PackageDetails')),

    extendedWarranty: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/CriteriaPage')),
    extendedWarrantyInsuredPackage: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/InsuredPackage')),
    extendedWarrantyInsuredPackageDetail: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/InsuredPackage/Components/PackageDetails')),
    extendedWarrantyRegistration: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/UserData')),
    extendedWarrantyConfirmation: lazy(() => import('../../containers/Private/ExtendedWarranty/pages/Confirmation'))
  }
}

export default routes
